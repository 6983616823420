import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { CartItem, Product } from "../models/Product";
import cartReducer, { CartAction } from "../reducers/CartReducer";
import { showAlert } from "../components/swal/GolSwal";

type Step =
  | "cart"
  | "purchase"
  | "loginCheckout"
  | "loggedInUserDetails"
  | "userDetails";

interface CartContextType {
  step: Step;
  setStep: React.Dispatch<React.SetStateAction<Step>>;
  cartState: { items: CartItem[] };
  cartDispatch: Dispatch<CartAction>;
  addToCart: (item: CartItem, quantity?: number) => void;
  addNumberedProductToCart: (item: CartItem, isGift: boolean) => void;
  addGiftProductToCart: (item: CartItem, quantity?: number) => void;
  removeOneFromCart: (id: number) => void;
  clearCart: () => void;
  removeOneNumberedProductFromCart: (id: number, number: number) => void;
  removeFromCart: (id: number) => void;
  getTotalPrice: () => number;
  getTotalItems: () => number;
  getAllItems: () => CartItem[];
  toCartItem: (product: Product) => CartItem;
  getProductQuantity: (id: number) => number;
  disableAddToCart: boolean;
  disableGiftNow: boolean;
  setDisableAddToCart: (value: boolean) => void;
  setDisableGiftNow: (value: boolean) => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [step, setStep] = useState<Step>("cart");
  const [disableAddToCart, setDisableAddToCart] = useState<boolean>(() => {
    const storedState = localStorage.getItem("disableAddToCart");
    return storedState ? JSON.parse(storedState) : false;
  });
  const [disableGiftNow, setDisableGiftNow] = useState<boolean>(() => {
    const storedState = localStorage.getItem("disableGiftNow");
    return storedState ? JSON.parse(storedState) : false;
  });

  const initializeCartState = (): { items: CartItem[] } => {
    const storedCart = localStorage.getItem("cart");
    return storedCart ? JSON.parse(storedCart) : { items: [] };
  };

  const [cartState, cartDispatch] = useReducer(
    cartReducer,
    initializeCartState()
  );

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartState));
  }, [cartState]);

  useEffect(() => {
    localStorage.setItem("disableAddToCart", JSON.stringify(disableAddToCart));
  }, [disableAddToCart]);

  useEffect(() => {
    localStorage.setItem("disableGiftNow", JSON.stringify(disableGiftNow));
  }, [disableGiftNow]);

  const addToCart = (product: CartItem, quantity: number = 1) => {
    cartDispatch({ type: "ADD_TO_CART", product: { ...product, quantity } });
    showAlert(
      "Added to Cart!",
      "The item has been added to your cart successfully.",
      undefined,
      "f-title-bold",
      "main-p text-color"
    );
    setDisableGiftNow(false);
    setDisableAddToCart(true);
  };

  const addNumberedProductToCart = (item: CartItem, isGift: boolean) => {
    cartDispatch({
      type: "ADD_NUMBERED_PRODUCT_TO_CART",
      product: { ...item, quantity: 1 },
    });
    if (!isGift) {
      showAlert(
        "Added to Cart!",
        "The item has been added to your cart successfully.",
        undefined,
        "f-title-bold",
        "main-p text-color"
      );
    }
  };

  const addGiftProductToCart = (product: CartItem, quantity: number = 1) => {
    cartDispatch({ type: "ADD_TO_CART", product: { ...product, quantity } });
    setDisableAddToCart(false);
    setDisableGiftNow(true);
  };

  const removeOneFromCart = (id: number) => {
    cartDispatch({ type: "REMOVE_ONE_FROM_CART", id });
    // setDisableAddToCart(false);
    // setDisableGiftNow(false);
  };

  const removeOneNumberedProductFromCart = (id: number, number: number) => {
    cartDispatch({ type: "REMOVE_ONE_NUMBERED_PRODUCT_FROM_CART", id, number });
    const selectedNumbers = JSON.parse(
      localStorage.getItem("selected_numbers") || "[]"
    );
    const updatedSelectedNumbers = selectedNumbers.filter(
      (selectedNumber: any) => selectedNumber !== number
    );

    localStorage.setItem(
      "selected_numbers",
      JSON.stringify(updatedSelectedNumbers)
    );
    localStorage.removeItem("random_number");
    localStorage.removeItem("random_to_cart");
    localStorage.removeItem("generated_first_time");
    setDisableAddToCart(false);
    setDisableGiftNow(false);
  };

  const removeFromCart = (id: number) => {
    cartDispatch({ type: "REMOVE_FROM_CART", id });
    setDisableAddToCart(false);
    setDisableGiftNow(false);
  };

  const clearCart = () => {
    cartDispatch({ type: "CLEAR_CART" });
    setDisableAddToCart(false);
    setDisableGiftNow(false);
  };

  const getTotalPrice = () => {
    return cartState.items.reduce(
      (total, item) => total + (item.price ?? 0) * item.quantity,
      0
    );
  };

  const getTotalItems = () => {
    return cartState.items.reduce((total, item) => total + item.quantity, 0);
  };

  const getAllItems = (): CartItem[] => {
    const allItems: CartItem[] = [];
    cartState.items.forEach((item) => {
      for (let i = 0; i < item.quantity; i++) {
        allItems.push({ ...item, quantity: 1 });
      }
    });
    return allItems;
  };

  const toCartItem = (product: Product) => {
    return { ...product, quantity: 1 };
  };

  const getProductQuantity = (id: number): number => {
    const product = cartState.items.find((item) => item.id === id);
    return product ? product.quantity : 0;
  };

  return (
    <CartContext.Provider
      value={{
        step,
        setStep,
        cartState,
        cartDispatch,
        addToCart,
        addNumberedProductToCart,
        removeFromCart,
        getTotalPrice,
        removeOneFromCart,
        removeOneNumberedProductFromCart,
        getTotalItems,
        getAllItems,
        toCartItem,
        clearCart,
        getProductQuantity,
        addGiftProductToCart,
        disableAddToCart,
        disableGiftNow,
        setDisableAddToCart,
        setDisableGiftNow,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = (): CartContextType => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
